import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import InstallationMacLayout from '../../components/Installation/InstallationMacLayout';
import { DOWNLOAD_MAC_OS_INTEL_ROUTE } from '../../const/routes.const';

const InstallationMacIntelPage = (): React.ReactElement => (
  <InstallationMacLayout
    downloadUrl={DOWNLOAD_MAC_OS_INTEL_ROUTE}
    title={<Trans>Download Octo Browser for MacOS (Intel)</Trans>}
  />
);

export default InstallationMacIntelPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["installationPage", "installationMacIntelPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
