// extracted by mini-css-extract-plugin
export var antBtnLg = "InstallationLayout-module--ant-btn-lg--KHFJk";
export var antBtnPrimary = "InstallationLayout-module--ant-btn-primary--zAT3m";
export var antFormItem = "InstallationLayout-module--ant-form-item--QpVaI";
export var antFormItemLabel = "InstallationLayout-module--ant-form-item-label--Wwjrj";
export var antInput = "InstallationLayout-module--ant-input--MZ0vX";
export var antMessageNotice = "InstallationLayout-module--ant-message-notice--goxrP";
export var antMessageNoticeContent = "InstallationLayout-module--ant-message-notice-content--yfjz0";
export var errorMessage = "InstallationLayout-module--errorMessage---IkPK";
export var title = "InstallationLayout-module--title--wCC0c";
export var wrapper = "InstallationLayout-module--wrapper--fXseO";
export var wrapperInner = "InstallationLayout-module--wrapperInner--ISPYY";