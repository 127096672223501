import React from 'react';
import { Trans } from 'gatsby-plugin-react-i18next';
import * as styles from './InstallationVideo.module.scss';

type InstallationVideoProps = {
  videoId: string;
};
const InstallationVideo = ({ videoId }: InstallationVideoProps): JSX.Element => (
  <div className={styles.wrapper}>
    <div className={styles.description}>
      <Trans>
        Watch our detailed video tutorial to learn how to register and activate an account, purchase
        a subscription, and create and configure an Octo Browser profile.
      </Trans>
    </div>
    <div className={styles.video}>
      <iframe src={`https://www.youtube.com/embed/${videoId}`} />
    </div>
  </div>
);

export default InstallationVideo;
