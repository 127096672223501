// extracted by mini-css-extract-plugin
export var antBtnLg = "InstallationStepsItem-module--ant-btn-lg--wCvca";
export var antBtnPrimary = "InstallationStepsItem-module--ant-btn-primary--sCFsi";
export var antFormItem = "InstallationStepsItem-module--ant-form-item--m0aHO";
export var antFormItemLabel = "InstallationStepsItem-module--ant-form-item-label--UrFnj";
export var antInput = "InstallationStepsItem-module--ant-input--apy31";
export var antMessageNotice = "InstallationStepsItem-module--ant-message-notice--OhezG";
export var antMessageNoticeContent = "InstallationStepsItem-module--ant-message-notice-content--NUmcy";
export var errorMessage = "InstallationStepsItem-module--errorMessage--yrbbn";
export var number = "InstallationStepsItem-module--number--X8uBI";
export var text = "InstallationStepsItem-module--text--Os3WY";
export var wrapper = "InstallationStepsItem-module--wrapper--vDoWC";