import React from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../../layouts';
import AppRating from '../AppRating';
import Note from '../Note/Note';
import { LanguageMap } from '../../const/language.const';
import LinkWrapper from '../common/LinkWrapper';
import { SUPPORT_ROUTE } from '../../const/routes.const';
import InstallationSteps from './InstallationSteps';
import * as styles from './InstallationLayout.module.scss';
import InstallationVideo from './InstallationVideo';
import {RegistrationAndPurchase} from "../../const/youtube.const";

type InstallationLayoutProps = {
  title: React.ReactNode;
  steps: React.ReactNode[];
  note: React.ReactNode;
};
const InstallationLayout = ({
  title,
  steps,
  note,
}: InstallationLayoutProps): JSX.Element => {
  const { language } = useI18next();
  const videoId = RegistrationAndPurchase[language] || RegistrationAndPurchase[LanguageMap.en.key];

  return (
    <Layout>
      <AppRating />
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <h1 className={styles.title}>{title}</h1>
          <InstallationSteps steps={steps} />
          <Note note={note} />
          <InstallationVideo videoId={videoId} />
          <Note
            note={
              <Trans i18nKey="If you have any questions left, don’t hesitate to contact us using our Telegram Technical Support Bot.">
                If you have any questions left, don’t hesitate to contact us using our Telegram <LinkWrapper external isNative url={SUPPORT_ROUTE}>
                  Technical Support Bot
                </LinkWrapper>
                .
              </Trans>
            }
          />
        </div>
      </div>
    </Layout>
  );
};

export default InstallationLayout;
