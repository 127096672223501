// extracted by mini-css-extract-plugin
export var antBtnLg = "InstallationVideo-module--ant-btn-lg--RG0+G";
export var antBtnPrimary = "InstallationVideo-module--ant-btn-primary--6EEpm";
export var antFormItem = "InstallationVideo-module--ant-form-item--YXqc5";
export var antFormItemLabel = "InstallationVideo-module--ant-form-item-label--xZcf7";
export var antInput = "InstallationVideo-module--ant-input--cRfVC";
export var antMessageNotice = "InstallationVideo-module--ant-message-notice--kf4bj";
export var antMessageNoticeContent = "InstallationVideo-module--ant-message-notice-content--mjPI0";
export var description = "InstallationVideo-module--description--Pirng";
export var errorMessage = "InstallationVideo-module--errorMessage--htG4T";
export var video = "InstallationVideo-module--video--hW6No";
export var wrapper = "InstallationVideo-module--wrapper--B6LWd";