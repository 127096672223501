import React from 'react';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import { docsDefaultLanguage, docsSupportedLanguages } from '../../const/language.const';
import { DOCUMENTATION_ROUTE, INSTALL_SETUP_DOCUMENTATION_ROUTE } from '../../const/routes.const';
import InstallationLayout from './InstallationLayout';
import LinkWrapper from '../common/LinkWrapper';
import { getUrlWithLocale } from '../../utils/url.utils';

type InstallationMacLayout = {
  downloadUrl: string;
  title: React.ReactNode;
};
const InstallationMacLayout = ({
  downloadUrl,
  title,
}: InstallationMacLayout): React.ReactElement => {
  const { language } = useI18next();

  return (
    <InstallationLayout
      note={
        <Trans i18nKey="If you encounter any difficulties while downloading or installing Octo Browser, please consult the FAQ.">
          If you encounter any difficulties while downloading or installing Octo Browser, please
          consult the <LinkWrapper
            external
            isNative
            url={`${getUrlWithLocale(
              language,
              DOCUMENTATION_ROUTE,
              docsDefaultLanguage,
              docsSupportedLanguages
            ).slice(0, -1)}${INSTALL_SETUP_DOCUMENTATION_ROUTE}`}
          >
            FAQ
          </LinkWrapper>
          .
        </Trans>
      }
      steps={[
        <Trans i18nKey="Open the downloaded Octo Browser .dmg file" key={1}>
          Open <LinkWrapper external isNative url={downloadUrl}>
            the downloaded Octo Browser .dmg file
          </LinkWrapper>
        </Trans>,
        <Trans key={2}>
          Drag and drop the Octo Browser icon into the Applications folder, and run it from there
        </Trans>,
        <Trans key={3}>Wait for the files to copy; don’t interrupt the installation</Trans>,
      ]}
      title={title}
    />
  );
};

export default InstallationMacLayout;
