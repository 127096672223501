import React from 'react';
import * as styles from './InstallationStepsItem.module.scss';

type InstallationStepsItemProps = {
  text: React.ReactNode;
  number: number;
};
const InstallationStepsItem = ({ text, number }: InstallationStepsItemProps): JSX.Element => (
  <div className={styles.wrapper}>
    <div className={styles.number}>{number}</div>
    <div className={styles.text}>{text}</div>
  </div>
);

export default InstallationStepsItem;
