import React from 'react';
import * as styles from './Note.module.scss';

type NoteProps = {
  note: React.ReactNode;
};

const Note = ({ note }: NoteProps): JSX.Element => <div className={styles.wrapper}>{note}</div>;

export default Note;
