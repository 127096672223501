import React from 'react';
import InstallationStepsItem from './InstallationStepsItem';
import * as styles from './InstallationSteps.module.scss';

type InstallationStepsProps = {
  steps: React.ReactNode[];
};
const InstallationSteps = ({ steps }: InstallationStepsProps): JSX.Element => (
  <div className={styles.wrapper}>
    {steps.map((step, idx) => (
      <InstallationStepsItem key={idx} number={idx + 1} text={step} />
    ))}
  </div>
);

export default InstallationSteps;
